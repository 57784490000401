export default (variants, rules, edit=false) => {
  return {
    productTypeVariantCode: {
      type: "select",
      label: "Available Product Variants",
      items: variants,
      itemText: "description",
      itemValue: "productTypeVariantCode",
      dense: true,
      col: 6,
      rules: [rules.required],
      outlined: true,
      hidden: edit
    },
    image: {
      type: "file",
      label: "Product Mockup Image(s)",
      dense: true,
      outlined: true,
      id: "artworks",
      name: "artworks",
      showSize: true,
      accept: "image/*",
      col: 6,
      appendIcon: "mdi-camera",
      prependIcon: null,
      multiple: true,
      ref: "image",
    },
  };
}
