<template>
  <v-card class="ma-4">
    <v-card-title>New Product Added</v-card-title>
    <v-list dense>
      <v-list-item>
        <v-list-item-content>SKU:</v-list-item-content>
        <v-list-item-content class="align-end">{{ savedProduct.sku }}</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Variant:</v-list-item-content>
        <v-list-item-content class="align-end">
          {{ savedProduct.variant }}
          {{ savedProduct.greetingCardSize || savedProduct.magnetSize || savedProduct.mountedPrintSize}}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Artist:</v-list-item-content>
        <v-list-item-content class="align-end">{{ savedProduct.artist }}</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Title:</v-list-item-content>
        <v-list-item-content class="align-end">{{ savedProduct.title }}</v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>Barcode:</v-list-item-content>
        <v-list-item-content class="align-end">
          <span>{{ savedProduct.barCode}}
            <v-btn icon color="primary" @click.native="barcodeDownload(savedProduct.barCode)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </span>
          <vue-barcode style="display: none" class="v-clickable" ref="barcode" :value="savedProduct.barCode" format="EAN13" height="30" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { svgToImgDownload } from '@/utilities/imageUtilities'
import VueBarcode from 'vue-barcode'

export default {
  props: {
    savedProduct: Object
  },
  components: {
    VueBarcode
  },
  methods: {
    barcodeDownload(sku) {
      const svg = document.querySelector(".vue-barcode-element");
      svgToImgDownload( svg, `${sku}-BARCODE.jpg` , 500, 200 )
    },
  }
}
</script>

